import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useExternalMedia } from "../../hooks/useExternalMedia";
import { Colors } from "../../utils/colors/colors";
import { Link } from "react-scroll";

type Props = {};

const LogoHeader = (props: Props) => {
  const { logo } = useExternalMedia();
  const [mouseEnter, setMouseEnter] = useState(false);
  const { whiteColor, goldColor } = Colors();
  return (
    <Container>
      <Row>
        <Col>
          <LogoSection>
            <Img src={logo} alt="logo" style={{ cursor: "pointer" }} />
            {/* <CalendlyPopup /> */}

            <Link
              to={"get-in-touch"}
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <button
                onClick={function (): void {
                  console.log("Button clicked");
                }}
                onMouseEnter={() => setMouseEnter(true)}
                onMouseLeave={() => setMouseEnter(false)}
                style={{
                  color: !mouseEnter ? ` ${whiteColor} ` : `${goldColor} `,
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                data-aos-once="true"
                data-aos="fade-up"
              >
                Get in Touch
              </button>
            </Link>
          </LogoSection>
        </Col>
      </Row>
    </Container>
  );
};

export default LogoHeader;

const LogoSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
const Img = styled.img`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 64px;
  height: 64px;
`;
