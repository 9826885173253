import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

type IProps = {
  formTitle: string;
  formSrc: string;
};

export default function FormComponent({ formTitle, formSrc }: IProps) {
  const [title, setTitle] = useState(`BASH | ${formTitle}`);

  useEffect(() => {
    document.title = title;
    setTitle(title);
  }, [title]);

  return (

    <Container>
      <Row style={{marginTop:"40px"}}>
        <Col>
          <div>
            <iframe
              width="100%"
              height="780px"
              title={formTitle}
              src={formSrc}
              style={{
                border: "none",
                maxWidth: "100%",
                maxHeight: "100vh",
              }}
            >
              {" "}
            </iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

