type IWhatIDo = {
  roleTitle: string;
  description: string;
  id: number;
 
}[];

export const whatIDo: IWhatIDo = [
  {
    roleTitle: "HRIS/HCM Consultation",
    description: "I streamline HR processes and enhance workforce management through the configuration and implementation of cutting-edge HRIS and HCM solutions to meet their unique needs, enabling them to improve operational efficiency, enhance employee engagement, and drive strategic decision-making. ",
    id: 0,
  
  },
  {
    roleTitle: "Project Management",
    description: "I provide comprehensive support in planning, executing, and monitoring projects across various domains. With a strong emphasis on communication, risk mitigation, and stakeholder management, I ensure that projects are delivered on time, within budget, and in alignment with organizational objectives.  ",
    id: 1,
   
  },
  {
    roleTitle: "Operations Management",
    description: "Drawing from my experience in process improvement, change management, and technology integration, I analyze and streamline operational processes, identify inefficiencies, and implement strategic solutions that help companies enhance productivity, reduce costs, and improve overall operational effectiveness. ",
    id: 2,
   
  },
  {
    roleTitle: "Data Analysis",
    description: "I transform raw data into actionable insights that drive strategic decision-making by employing advanced analytical techniques and industry-specific tools that shed light on critical business challenges, allowing organizations to make data-driven decisions that optimize operations, increase efficiency, and fuel growth. ",
    id: 3,
  
  },
 
  {
    roleTitle: "IT Operations",
    description: "With a focus on continuous improvement, I ensure that IT operations are closely aligned with business objectives, enabling organizations to leverage technology as a strategic advantage. I collaborate with organizations to streamline their technology infrastructure, ensuring optimal performance, reliability, and security. ",
    id: 4,
    
  },
  {
    roleTitle: "Business Consultation",
    description: "Through a collaborative approach, I offer comprehensive advisory services tailored to the unique needs of my clients. I conduct in-depth assessments, identify areas for improvement, and develop actionable strategies that empower businesses to make informed decisions, streamline operations, and enhance their competitive positioning. ",
    id: 5,
    
  },
];
