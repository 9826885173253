import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { HOME, INDEX,GMB, TALENT_ACQUISITION, BOOK_APPOINTMENT } from "./routes/path.routes";
import GoogleMyBusinessPage from "./pages/google-my-business-page"
import TalentAcquisitionPage from "./pages/talent-acquisition-page"
import HomePage from "./pages/Homepage";

function App() {
  useEffect(() => {
    AOS.init({
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <>
    <BrowserRouter>
    <Routes>
        <Route>
          <Route element={<HomePage />} path={HOME} />
          <Route element={<HomePage />} path={INDEX} />
          <Route element={<TalentAcquisitionPage />} path={TALENT_ACQUISITION} />
          <Route element={<GoogleMyBusinessPage />} path={GMB} />
          <Route element={<TalentAcquisitionPage />} path={BOOK_APPOINTMENT} />

        </Route>
      </Routes>
    </BrowserRouter>
 
    </>
  );
}

export default App;
