import React from "react";
import { Container, Row } from "react-bootstrap";
import TitleHeading from "../../utils/title-heading/TitleHeading";
import styled from "styled-components";
import { useExternalMedia } from "../../hooks/useExternalMedia";
import {  Link } from "react-router-dom";
import {
  GMB,
  TALENT_ACQUISITION,
  BOOK_APPOINTMENT,
} from "../../routes/path.routes";

const MyServices = () => {
  const { talentAcquisition, appointment, gmb } =
    useExternalMedia();


  return (
    <Container
      style={{
        marginTop: "100px",
      }}
    >
      <Row>
        <TitleHeading title={"Services I Offer "} />
      </Row>
      <Row>
        <BrandLogoWrapper data-aos-once="true" data-aos="fade-up">
          <Link
            to={TALENT_ACQUISITION}
            style={{
              textDecoration: "none",
              marginBottom: "20px",
            }}
            data-aos-once="true"
            data-aos="fade-up"
          >
            <BrandLogo data-aos-once="true" data-aos="fade-up">
              <img src={talentAcquisition} alt="bacf-logo" />
            </BrandLogo>
          </Link>
          <Link
            to={GMB}
            style={{
              textDecoration: "none",
              marginBottom: "20px",
            }}
            data-aos-once="true"
            data-aos="fade-up"
          >
            <BrandLogo data-aos-once="true" data-aos="fade-up">
              <img src={gmb} alt="bacf-logo" />
            </BrandLogo>
          </Link>{" "}
          <Link
            to={BOOK_APPOINTMENT}
            style={{
              textDecoration: "none",
              marginBottom: "20px",
            }}
            data-aos-once="true"
            data-aos="fade-up"
          >
            <BrandLogo data-aos-once="true" data-aos="fade-up">
              <img src={appointment} alt="bacf-logo" />
            </BrandLogo>
          </Link>
        </BrandLogoWrapper>
      </Row>
    </Container>
  );
};

export default MyServices;

const BrandLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;

  width: 240px;
  height: 100px;
  margin-bottom: 1rem;
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 7px 7px 0px rgba(255, 255, 255, 0.1);
`;

const BrandLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
  margin-top: 80px;

  @media (min-width: 600px) {
    justify-content: space-between;
  }
`;
