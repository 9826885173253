import LogoHeader from "../../components/hero/LogoHeader";
import GetInTouch from "../../components/get-in-touch";
import FormComponent from "../../utils/form-aside/FormComponent";
import { useFormDetails } from "../../hooks/useFormDetails";

export default function Survey() {
  const { formTitle, formSrc } = useFormDetails();

  return (
    <>
      <LogoHeader />
      <FormComponent formTitle={formTitle} formSrc={formSrc} />
      <GetInTouch />
    </>
  );
}
