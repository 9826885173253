type ICommunities = {
  communityTitle: string;
  description: string;
  id: number;
  communityImage: string;
}[];

export const communities: ICommunities = [
  {
    communityTitle: "Baltimore Black Techies",
    description:
      "A vibrant network of professionals dedicated to promoting diversity, inclusion, and career growth in the technology sector while fostering invaluable connections, knowledge-sharing, and mentorship opportunities.  ",
    id: 0,
    communityImage:
      "https://res.cloudinary.com/bacf/image/upload/v1710107615/BASH-PERSONAL-WEBSITE/Frame_46_1_snmzjc.png",
  },
  {
    communityTitle: "Hack Baltimore",
    description:
      "A dynamic community that brings together innovative minds from various backgrounds to collaborate, ideate, and solve real-world challenges through technology. ",
    id: 1,
    communityImage:
      "https://res.cloudinary.com/bacf/image/upload/v1710107612/BASH-PERSONAL-WEBSITE/Frame_46_2_haqzwl.png",
  },
  {
    communityTitle: "HR-Techish",
    description:
      "A cutting-edge platform that connects HR professionals, technology enthusiasts, and industry leaders which I founded to share my knowledge and gain valuable perspectives from experienced practitioners. ",
    id: 2,
    communityImage:
      "https://res.cloudinary.com/bacf/image/upload/v1710107612/BASH-PERSONAL-WEBSITE/Frame_46_3_prdjdm.png",
  },
];
