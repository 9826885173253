import React from "react";
import "./index.css";
import LogoHeader from "./LogoHeader";
import BashBio from "./BashBio";
import BashBrief from "./BashBrief";

const NewHero: React.FC = () => {
  return (
    <section className="bg-image">
      <div className="content">
        <>
          <LogoHeader />
          <BashBio />
          <BashBrief />
        </>
      </div>
    </section>
  );
};

export default NewHero;
