import { useLocation } from "react-router-dom";
import { useLocationPathName } from "./useLocationPathname";
import { GMB, BOOK_APPOINTMENT, TALENT_ACQUISITION } from "../routes/path.routes";


type IUseFormDetails = {
  actionText: string;
  actionSubText: string;
  formTitle: string;
  formSrc: string;
}

export const useFormDetails = (): IUseFormDetails => {



  const location = useLocation();
  const useLocationPathNameProps = {
    location,
    limit: 1,
    isFirstLetterUppercased: false,
    searchQuery: "type",
  };


  const {
    path
  } = useLocationPathName(useLocationPathNameProps);

  let actionText: string = "",
    actionSubText: string = "",
    formTitle: string = "",
    formSrc: string = "";


  switch (path) {

    case GMB:
      actionText = "";
      actionSubText = "";
      formTitle = "Google My Business Setup Sign-up Form";
      formSrc = "https://forms.office.com/Pages/ResponsePage.aspx?id=9_D9R9N8HEKj_htcrwWr8lQVvz8ef0VNoh5XZQBWPbVURENWMTY1VVpTQlBBWDkwTU9BSFVSQVVCVS4u";
      break;

    case TALENT_ACQUISITION:
      actionText = "";
      actionSubText = "";
      formTitle = "Talent Requisition Form";
      formSrc = "https://forms.office.com/Pages/ResponsePage.aspx?id=9_D9R9N8HEKj_htcrwWr8lEpEeLkXtVDqBx9xy8BcTtUOFI5SUdNRUJESFpIQThNUUZBMVgzRkY3US4u&origin=Invitation&channel=0";
      break;



    case BOOK_APPOINTMENT:
      actionText = "";
      actionSubText = "";
      formTitle = "Eratek Booking Setup Form";
      formSrc = "https://outlook.office365.com/owa/calendar/EratekDiscoveryCall@bacfinc.com/bookings/s/V1bwuIBVtkyYo3Hsbm_VnA2";
      break;



    default:
      actionText = "";
      actionSubText = "";
      formTitle = "";
      formSrc = "";
      break;
  }


  return {
    actionText,
    actionSubText,
    formTitle,
    formSrc,
  }
}